$fw-lighter: 400;
$fw-normal: 500;
$fw-bold: 600;

$fs-xxs: 12px;
$fs-xs: 13px;
$fs-small: 14px;
$fs-normal: 16px;
$fs-regular: 18px;
$fs-medium: 21px;
$fs-large: 24px;
$fs-xl: 28px;
$fs-xxl: 38px;
$fs-xxxl: 42px;


