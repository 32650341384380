// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';

@import "src/app/styles/colors";
@import "src/app/styles/variables";
@import "src/app/styles/font";
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-material-admin-primary: mat-palette($mat-indigo);
$angular-material-admin-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$angular-material-admin-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$angular-material-admin-theme: mat-light-theme($angular-material-admin-primary, $angular-material-admin-accent, $angular-material-admin-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($angular-material-admin-theme);



$ama-yellow: (50: $yellow,
  100: #ECECEC,
  200: $yellow,
  300: $yellow,
  400: $yellow,
  500: $yellow,
  600: $yellow,
  700: $yellow,
  800: $yellow,
  900: $yellow,
  A100: $yellow,
  A200: $yellow,
  A400: $yellow,
  A700: $yellow,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ));

$ama-pink: (50: $pink,
  100: #ECECEC,
  200: $pink,
  300: $pink,
  400: $pink,
  500: $pink,
  600: $pink,
  700: $pink,
  800: $pink,
  900: $pink,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ));

$ama-blue: (50: $blue,
  100: #ECECEC,
  200: $blue,
  300: $blue,
  400: $blue,
  500: $blue,
  600: $blue,
  700: $blue,
  800: $blue,
  900: $blue,
  A100: $blue,
  A200: $blue,
  A400: $blue,
  A700: $blue,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ));

$ama-primary: mat-palette($ama-blue);
$ama-accent: mat-palette($ama-yellow);
$ama-warn: mat-palette($ama-pink);

$ama-theme: mat-light-theme($ama-primary, $ama-accent, $ama-warn);
@include angular-material-theme($ama-theme);

button.mat-menu-item {
  font-size: 16px;
}

button.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  font-size: 16px;
}

mat-select.mat-select {
  display: inline;
  font-size: 16px;
  font-weight: 300;
  color: $dark-grey;
  border: 1px solid $light-grey;
  border-radius: 4px;
  padding: 8px 10px;

  & .mat-select-value {
    display: inline;
  }

  & .mat-select-arrow {
    color: $light-grey;
    margin-right: 0;
  }
}

.mat-option {
  font-size: 16px;
  height: 36px;
  padding: 6px 16px;
  color: $dark-grey;

  &.mat-focus-indicator.mat-selected.mat-active {
    background-color: $blue-white;
    color: $dark-grey;
  }

  &:hover:not(.mat-option-disabled) {
    background-color: $blue-white;
  }
}

.mat-form-field-underline {
  display: none;
}

* {
  // font-family: 'Quicksand', sans-serif;
  font-family: 'Montserrat', sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
  font-weight: 400;
  font-size: 48px;
}

.mat-h2,
.mat-headline,
.mat-typography h2 {
  font-weight: 500;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 5px;
}

.mat-h3,
.mat-headline,
.mat-typography h3 {
  font-weight: 400;
  font-size: 26px;
}

.mat-h4,
.mat-headline,
.mat-typography h4 {
  font-weight: 400;
  font-size: 24px;
}

.mat-h5,
.mat-headline,
.mat-typography h5 {
  font-weight: 400;
  font-size: 21px;
}

.mat-h6,
.mat-headline,
.mat-typography h6 {
  font-weight: 400;
  font-size: 18px;
}

.mat-body p,
.mat-body-1 p,
.mat-typography p {
  margin-bottom: 16px;
  line-height: normal;
}

button.mat-fab .mat-button-wrapper {
  padding: 0;
}

.mat-card {
  color: $card-font-color;
  margin: 16px;
}

.mat-card-title p {
  font-size: 24px;
  font-weight: 600;
  color: #323A47;
}

mat-toolbar.page-header {
  display: flex;
  justify-content: space-between;
  padding: 50px 24px 16px 24px;
  background: none;
  height: auto;

  @media (max-width: $small) {
    flex-direction: column;
    align-items: baseline;
  }
}

.mat-toolbar h1 {
  font-weight: 400;
  font-size: 38.4px;
  color: $toolbar-title-font-color;
  line-height: 1;
  letter-spacing: -0.2186px;

  @media (max-width: $small) {
    white-space: normal;
  }
}

.mat-toolbar button {
  height: 41.6px;
  width: auto;
  line-height: 1.75;
  font-size: 0.9375rem;
  font-weight: 400;

  @media (max-width: $small) {
    margin-top: 16px;
  }
}

mat-tab-header .mat-tab-label,
.mat-tab-link {
  // text-transform: uppercase;
  color: $tabs-header-font-color;
  font-weight: $fw-normal;
  width: 50%;
  min-width: 0;
  padding: 0;
  opacity: 1;
}

mat-tab-header .mat-tab-label-active {
  color: $tabs-header-font-color-active;
}

mat-tab-group.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: $tabs-ink-bar-color;
}

mat-tab-body div.mat-tab-body-content {
  overflow: hidden;
}

.toast-top-center {
  top: 76px;
}

.toast-top-left {
  top: 76px;
}

.toast-top-right {
  top: 76px;
}

.toast-bottom-center {
  bottom: 12px;
}

.toast-container .ngx-toastr {
  padding: 16px;
  border-radius: 45px;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px $light-grey;
}

.mat-icon {
  padding-right: 3px;
  padding-bottom: 5px;
}

.material-icons {
  font-size: 28px;
}

button.mat-mini-fab .mat-button-wrapper {
  padding: 0;
}

button.mat-mini-fab {
  height: 36px;
  width: 36px;
}

div.mat-menu-panel {
  margin-top: 20px;
}

.fa {
  font-size: 1.5em;
}

.apexcharts-xaxistooltip.apexcharts-xaxistooltip-bottom.apexcharts-theme-light {
  background-color: $chart-tooltip-background-color;
  color: $chart-tooltip-font-color;
  border: none;
}

div.apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: $chart-tooltip-border-color;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  display: none
}

::-webkit-scrollbar-track {
  background-color: $scrollbar-track-color;
}

::-webkit-scrollbar-track-piece {
  background-color: $scrollbar-track-piece-color;
}

::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: $light-grey;
  border-radius: 36px;
}

::-webkit-scrollbar-corner {
  background-color: $scrollbar-corner-color;
}

::-webkit-resizer {
  background-color: $dark-grey;
}


// Common css
.add-pointer {
  cursor: pointer;
}



/* new css*/

.mat-card,
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 13px 12px rgba(0, 0, 0, 0.03);
}

.layout-content[_ngcontent-sea-c135] {
  background-color: #EEF2F6;
}

.mat-card[class] {
  color: #4a4a4a;
  margin: 0;
  padding: 16px 0;
  border-radius: 8px;
}

.layout-content {
  padding: 25px;
  // background-color: #FFF !important;
  box-sizing: border-box;
}

.remove-box-shadow {
  box-shadow: none !important;
}

.breadcrumb-card {
  padding: 16px !important;
  padding-left: 0px !important;

  // margin-bottom: 24px !important;
  .mat-card-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .breadcrumb-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 20px;
    padding: 0;

    .breadcrumb-list-item {
      color: #323A47;
      cursor: pointer;
      font-size: 15px;
      outline: none;
      width: auto;
      display: flex;

      &::after {
        content: "/";
        color: rgba(144, 154, 169, 1);
        margin: 0 10px;
      }

      &:last-child {
        color: #909AA9;

        &::after {
          display: none;
        }
      }
    }
  }
}

.width100 {
  width: 100%;
}

.position-relative {
  position: relative;
}

.date-time {
  color: #909AA9;
  font-size: 15px;
}

.form-group {
  margin-bottom: 20px;

  .dd-arrow {
    position: absolute;
    background-image: url("assets/images/icons/down-arrow.svg");
    width: 12px;
    height: 8px;
    background-repeat: no-repeat;
    bottom: 15px;
    right: 10px;
    pointer-events: none;
    background-size: 100%;
  }

  .dd-delete {
    position: absolute;
    background-image: url("assets/images/icons/delete.svg");
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    top: 50%;
    right: 14px;
    background-size: 100%;
    margin-top: -8px;
  }
}

.form-label {
  margin-bottom: 5px;
  display: block;
  font-size: 12px;
  color: #040404;
}

.form-control {
  padding: 10px;
  // height: 44px;
  font-weight: 400;
  background: #EEF2F6;
  border-radius: 8px !important;
  background-clip: padding-box;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-sizing: border-box;
  outline: none;
  font-size: 15px;
  line-height: 150%;
  color: #323A47;

}

textarea.form-control {
  height: 137px;
  resize: none;
}

.mat-grid-tile .mat-figure {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
  margin: 0;
}

.frm-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px !important;

  >div {
    display: flex;
    align-items: center;

    .btn-back {
      margin-right: 25px;
      display: flex;
    }

    h4 {
      font-weight: 500;
      font-size: 24px;
      color: #323A47;
      margin-bottom: 0;
    }
  }
}

.frm-box-outer {
  * {
    box-sizing: border-box;
  }

  display: flex;

  // margin-left:-12px;
  // margin-right:-12px;
  .frm-box {
    padding-left: 12px;
    padding-right: 60px;
    width: 33.3333%;
    flex-grow: 1;

    .date-time-box {
      display: flex;
      margin-left: -8px;
      margin-right: -8px;

      >.form-group {
        width: 50%;
        padding-left: 8px;
        padding-right: 8px;
        flex-grow: 1;
      }
    }
  }
}

.pdf-data {
  padding: 24px;
  border-radius: 8px;
  background: #EEF2F6;
  // margin-top: 20px;
  margin: 12px;

  .pdf-data-header {
    position: relative;

    h4 {
      font-weight: bold;
      font-size: 13px;
      color: #323A47;

      &::after {
        background-image: url("assets/images/icons/down-arrow.svg");
        content: "";
        width: 16px;
        height: 9px;
        position: absolute;
        background-size: 100%;
        background-repeat: no-repeat;
        right: 0;
        transform: rotate(180deg);
      }
    }
  }

  p {
    font-size: 13px;
    margin-bottom: 5px;

    strong {
      margin-right: 3px;
    }
  }

  .btn-edit-invoice {
    margin-top: 20px;
    background-color: transparent;
    border: none;
    font-size: 13px;
    color: #000000;
    padding: 0;
    display: flex;
    align-items: center;

    span {
      margin-right: 8px;
    }
  }
}

.tables-wrapper {
  padding: 0;
}

.page-header[class] {
  padding: 0 !important;
}

.icon-btn {
  border: none;
  background-color: transparent;
  padding: 0;
}

.position-relative {
  position: relative;
}

.product-table-box {
  margin: 12px;
}


.header-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 150px;
  width: 100%;

  &::before {
    background: linear-gradient(180deg, #F6F6F6 0%, rgba(255, 241, 241, 0.986753) 0.01%, rgba(255, 255, 255, 0) 100%);
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.bg-transparent {
  background: none !important;
  background-color: transparent !important;
}
.mat-date-range-input-container{
  align-items: flex-start !important;
  justify-content: end;
}
.mat-date-range-input-start-wrapper{
  overflow: visible !important;
}
.mat-card-title {
  font-size: 18px !important;
}



@media screen and (max-width:1200px) {
  .frm-box-outer {
    flex-direction: column;
  }

  .frm-box-outer .frm-box {
    width: 100%;
  }

  .frm-box-outer .frm-box .date-time-box>.form-group {
    width: 100%;
  }

}

@media screen and (max-width:767px) {
  .layout-content {
    padding: 16px;
  }

  .breadcrumb-card {
    .mat-card-content {
      flex-direction: column;

      .date-time {
        margin-top: 10px;
      }
    }
  }
}

.mat-dialog-container {
  border-radius: 10px !important;
  background-color: #eef2f6 !important;

}

.invalid-feed {
  color: red;
  font-size: 13px;
  position: absolute;
  line-height: 1;
  margin-top: 3px;
}

// .feedback {
//   color: red;   position: inherit;
//   font-size: 14px;
//   bottom: -7px;
//   left: 0;
// }

.invalid-feedback {
  display: block !important;
  color: red;
  font-size: 13px;
  position: absolute;
  top: 100%;
  left: 0;
  line-height: 1;
  margin-top: 3px;
}

.valid-feedback {
  color: green;
  font-size: 14px;
  margin-top: -25px;
  position: absolute;
}

.mat-dialog-actions {
  display: flex;
  justify-content: space-between;
}

.toast-container .ngx-toastr {
  padding: 13px 0px 12px 59px !important;
}

//******* change ngx time-picker color *****
.mat-dialog-actions {

  .mat-button.mat-primary,
  .mat-icon-button.mat-primary,
  .mat-stroked-button.mat-primary {
    color: #E73916 !important;
  }
}

.timepicker {
  .timepicker-header {
    background-color: #E73916 !important;
  }

  .mat-flat-button.mat-primary,
  .mat-raised-button.mat-primary,
  .mat-fab.mat-primary,
  .mat-mini-fab.mat-primary {
    background-color: #E73916;
  }

  .clock-face__number {
    >span.active {
      background-color: #E73916 !important;
    }
  }

  .mat-toolbar.mat-primary {
    background: #E73916;
  }
}

//******* change ngx time-picker color *****



.table-search {
  display: flex;
  align-items: center;
  max-width: 350px;

  input {
    background-color: #FFF;
    border: 1px solid #eef2f6;
    width: 250px;
    height: 37px;
  }
}

.text-right {
  text-align: right;
}

.mb-20 {
  margin-bottom: 20px;
}

.pt-0 {
  padding-top: 0 !important;
}

.d-flex {
  display: flex !important;
}

.scrollable-table {
  height: calc(100vh - 260px);
  max-width: 100%;
  overflow: auto;
}

.datePicker .mat-form-field-infix {
  border-top: 0px;
  padding: 0px !important;
}

.datePicker2 .mat-form-field-infix,
.datePicker2 .mat-form-field-suffix {
  border-top: 0px;
  padding: 0px !important;
  // top: -9px;
}

.pagination {
  justify-content: end;
}

th {
  white-space: nowrap !important;
}

.mat-sort-header-button input {
  min-width: 100px;
}

.mat-sort-header-button {
  text-align: left;
}

.filter-table {
  .mat-tab-label {
    height: 30px;
    text-transform: capitalize;
  }

  .mat-tab-body-wrapper {
    padding-top: 10px;
  }

  &.mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #FF0028;
  }

  .mat-tab-label-active {
    color: #FF0028;
  }

  table {
    td {
      border-color: #e9e9e9;
    }

    >:not(caption)>*>* {
      padding: 0.3rem 0.5rem;
    }
  }
}

.fill-button{
  margin-left: 10px;
  background: linear-gradient(180deg, #E73916 0%, #D1472D 100%);
  color: white;
}



.mat-dialog-container {
  position: relative;
  // padding: 15px !important;

  .preview {
    top: 10px;
    right: 10px;
    width: 36px;
    height: 36px;
    position: absolute;
    z-index: 99;
    background-color: #eef2f6;
    padding: 6px;
    margin-top: 0 !important;
    margin-right: 0 !important;
    img{
      width: 100%;
    }
  }

  .preview-image {
    width: 100%;
    max-width: 100% !important;
  }
}
.product-preview-img .mat-dialog-container {
  padding: 0 !important;
}
.mat-sort-header-container span {
  display: block;
  text-align: left;
}
.sidebar-menu{
  .mat-list-item-content{
    padding: 0 0 0 16px !important;
  }
}

.mat-form-field-wrapper{
  padding-bottom: 0 !important;
}

.scroll-container{
  height: calc(100% - 100px);
  > *{
    height: 100%;
    flex-grow: 1;
  }
}
.branch-tab .mat-tab-body-content {
  overflow-y: auto;
  height: calc(100% - 30px);
}

.qrimgclass img {
  height: 40px;
  width: 40px;
}

.cdk-global-overlay-wrapper{
  // align-items: flex-start !important;
  // margin-top: 60px;
}

.custom-loader{
  display: flex;
  align-items: center;
  justify-content: center;
}
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #E73916 !important;
}

.gobd-Popup h2 {
  font-size: 14px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 25px;
}
.gobd-Popup h6 {
  color: #E73916;
  font-size: 14px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2;
}
.gobd-Popup .mat-date-range-input {
  background-color: #fff;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 8px 0 0 8px !important;
  margin-left: 10px;
  border-right: 0;
}
.gobd-Popup .mat-datepicker-toggle .mat-icon-button {
  background-color: #fff;
  color: var(--bs-body-color);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0 8px 8px 0 !important;
  border-left: 0;
  height: 38px;
  line-height: 36px;
}
.gobd-Popup .mat-dialog-content {
  margin-bottom: 35px;
}
.gobd-Popup .mat-radio-button {
  margin-right: 15px;
}
.gobd-Popup .mat-dialog-actions {
  margin-top: 20px;
  margin-bottom: -10px;
}
.gobd-Popup .mat-dialog-actions button {
  width: 48%;
}
.gobd-Popup .mat-dialog-actions button:first-child {
  background: linear-gradient(180deg, #E73916 0%, #D1472D 100%);
  color: white;
  height: 39px;
}
.gobd-Popup .mat-dialog-actions button:last-child {
  border: 1px solid #5C6471;
  color: #5C6471;
}
.gobd-Popup .gobd-close {
  position: absolute;
  right: 25px;
  top: 15px;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #db4124;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #db4124;
}